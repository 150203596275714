<template>
  <div>
    <label class="contacts-label">{{ $t('Attachments') }}</label>
    <div class="d-flex justify-content-end">
      <feather-icon
        style="min-width: 37px; min-height: 37px"
        icon="LAddButtonIconWhite"
        size="24"
        :class="`cursor-pointer feather-add-icon ${isEdit ? 'is-editable-file-upload': ''}`"
        @click="showByRef"
      />
    </div>
    <!-- ===================================== -->
    <table
      class="table b-table table-striped"
    >
      <thead>
        <tr>
          <!--it checks if any file uploaded, not being accurate when empty-->
          <th
            v-if="attachments.length > 0"
            style="width: 150px;"
            class="pr-0 pl-1"
          />
          <th
            :style="attachments.length > 0 ? 'width: 145px;' : ''"
            class="pl-0 py-1 pr-1"
          >
            {{ $t('File Name') }}
          </th>
          <th
            style="width: 185px;"
            class="pl-0 py-1 pr-1"
          >
            {{ $t('Date') }}
          </th>
          <th
            class="pl-0 py-1 pr-1"
          >
            {{ $t('Type') }}
          </th>
          <th
            style="width: 125px;"
            class="pl-0 py-1 pr-1"
          >
            {{ $t('Category') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(file, index) in attachments"
          :key="index"
          class="pb-2"
        >
          <template v-if="file.id">
            <td
              class="pr-0 pl-0"
            >
              <span class="upload-file__files-list-item-del">
                <feather-icon
                  icon="LTrashIcon"
                  size="28"
                  style="padding: 5px; margin-left: 5px !important;"
                  class="mr-1 ml-1 border-dotted feather-trash-icon defaultIconColor"
                  :class="isEdit || 'cursor-pointer greyIcon'"
                  @click="isEdit || deleteAttachment(file.id, index)"
                />
              </span>
              <span>
                <a @click="showAttachment(file)">
                  <feather-icon
                    icon="LEyeIcon"
                    size="28"
                    style="padding: 5px;"
                    class="cursor-pointer border-dotted feather-eye-icon greyIcon"
                  />
                </a>
              </span>
            </td>
            <td
              class="pr-1 pl-0"
            >
              {{ truncateFileName(file.name) }}
            </td>
            <td class="pr-1 pl-0">
              {{ formatDate(file.created_at) }}
            </td>
            <td class="pr-1 pl-0">
              {{ file.attachment_type }}
            </td>
            <td class="pr-1 pl-0">
              {{ file.attachment_category }}
            </td>
          </template>
          <template v-else>
            <td
              class="pr-0 pl-0"
            >
              <span class="upload-file__files-list-item-del">
                <feather-icon
                  icon="LTrashIcon"
                  size="28"
                  style="padding: 5px; margin-left: 5px !important;"
                  class="cursor-pointer mr-1 ml-1 border-dotted feather-trash-icon"
                  @click="deleteAttachment(file.id, index)"
                />
              </span>
              <span>
                <a @click="showAttachment(file)">
                  <feather-icon
                    icon="LEyeIcon"
                    size="28"
                    style="padding: 5px;"
                    class="cursor-pointer border-dotted feather-eye-icon greyIcon"
                  />
                </a>
              </span>
            </td>
            <td
              class="pr-1 pl-0"
            >
              {{ truncateFileName(file.file.name) }}
            </td>
            <td class="pr-1 pl-0">
              {{ currentDate }}
            </td>
            <td class="pr-1 pl-0">
              {{ getDocumentTypeByString(file.file.type) }}
            </td>
            <td class="pr-1 pl-0">
              {{ getListOfCategories(file.attachment_category_id) }}
            </td>
          </template>
        </tr>
      </tbody>
    </table>

    <!-- ===================================== -->
    <div class="l-file-upload mb-1">
      <div
        v-if="!attachments.length"
        class="text-center upload-file__files-list-no-data border mb-md-1"
      >
        {{ $t('None Found') }}
      </div>
      <div
        v-else
        class="upload-file__files-list"
      >
        <div
          v-if="isLoading"
          class="d-flex justify-content-center my-2"
        >
          <b-spinner />
        </div>
        <div v-if="!isLoading" />

      </div>
      <l-file-upload
        ref="refLFileUploadInput"
        v-model="chosenFiles"
        :is-editable="ACCESS_ABILITY_FOR_CONTACTS"
        :do-disabled="isEdit"
        :accept-extension-array="['.jpg', '.jpeg', '.png', '.tif', '.pdf', '.bmp']"
        @input="showModalToChoseCategory"
      />

    </div>
    <b-modal
      id="attachment-create-modal"
      centered
      hide-header
      hide-footer
      body-class="l-modal__body"
      footer-class="l-modal-footer"
      ok-title="Upload"
      ok-variant="success"
      :ok-disabled="isOkUploadBtnActive"
      :cancel-disabled="isCancelUploadBtnActive"
      cancel-variant="outline-primary"
      @hidden="clearData"
      @ok="uploadFile"
    >
      <b-overlay :show="isUploading">
        <div class="l-modal__container p-2">
          <b-row>
            <b-col cols="12">
              <h3
                class="l-modal__title"
                style="font-size: 24px"
              > {{ $t('How should this file be categorized?') }}
              </h3>
            </b-col>
            <b-col>
              <p class="text-center">
                {{ $t('Please mind that you can only upload files up to 4MB each.') }}
              </p>
              <label class="ml-1">Category</label>
              <l-select
                v-model="sendData.attachment_category_id"
                name="attachment_category_id"
                :field="{
                  options: { transition: '' },
                  store: 'attachmentCategoryListContact',
                }"
              />
            </b-col>
          </b-row>
        </div>
      </b-overlay>
      <b-row class="text-center">
        <b-col
          cols="6"
          class="text-left"
        >
          <b-button
            variant="outline-primary"
            :disabled="isCancelUploadBtnActive"
            class="font-medium-1 font-weight-bolder text-dark align-center mb-2 pl-3 pr-3 ml-2"
            @click="hideModalToChoseCategory"
          >
            <feather-icon
              icon="LCancelIcon"
              size="16"
              class="align-center"
            /> {{ $t('Cancel') }}
          </b-button>
        </b-col>
        <b-col
          cols="6"
          class="text-right"
        >
          <b-button
            variant="success"
            class="font-medium-1 font-weight-bolder align-center mb-2 pl-3 pr-3 mr-2"
            :disabled="isOkUploadBtnActive"
            @click="uploadFile"
          >{{ $t('Upload') }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="delete-confirmation-modal"
      centered
      hide-header
      body-class="l-modal__body"
      footer-class="l-modal-footer"
      ok-title="Yes"
      ok-variant="success"
      :ok-disabled="isOkTrashBtnActive"
      :cancel-disabled="isCancelTrashBtnActive"
      cancel-variant="outline-primary"
      @ok="deleteSelectedItem"
      @cancel="hideModalConfirmation"
      @hide="hideModalConfirmation"
    >
      <b-overlay :show="isMovingToTrash">
        <div class="l-modal__container">
          <b-row>
            <b-col cols="12">
              <h3 class="l-modal__title">
                {{ $t('Are you sure you want to delete this document? This operation can not be undone') }}
              </h3>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BModal,
  BOverlay,
  BSpinner, BButton,
} from 'bootstrap-vue'
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'
import axios from 'axios'
import lFileUpload from '@/views/main/customers/view/attachments/LFileUpload.vue'
import { getCurrentFormattedDate, formatDate } from '@core/utils/utils'
import config from '../contactsConfig'

export default {
  name: 'ContactAttachment',
  components: {
    BButton,
    lFileUpload,
    BRow,
    BCol,
    BModal,
    BOverlay,
    BSpinner,
  },
  props: {
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    acceptExtensionArray: {
      type: Array,
      required: false,
      default: () => [],
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uploadedFiles: [],
      attachmentsArrForSend: [],
      isUploading: false,
      isLoading: false,
      isMovingToTrash: false,
      deletingItemId: null,
      deletingItemIndex: null,
      inputValue: null,
      chosenFiles: null,
      moveToTrashItemId: null,
      allowedFormats: ['jpg', 'jpeg', 'png', 'tif', 'pdf', 'bmp'],
      currentDate: getCurrentFormattedDate().slice(0, -9),
      sendData: {
        attachment_category_id: null,
        model_type: 1,
        file: null,
      },
    }
  },
  computed: {
    attachmentCategoryListContact() {
      return this.$store.state.listModule.attachmentCategoryListContact
    },
    formattedAcceptProps() {
      return this.acceptExtensionArray.join(',')
    },
    attachments() {
      return this.$store.state[this.MODULE_NAME].contactsForm.attachments ?? []
    },
    contact() {
      return this.$store.state[this.MODULE_NAME].contactsForm
    },
    isOkUploadBtnActive() {
      return this.isUploading || !this.sendData.attachment_category_id
    },
    isCancelUploadBtnActive() {
      return this.isUploading
    },
    isOkTrashBtnActive() {
      return this.isUploading
    },
    isCancelTrashBtnActive() {
      return this.isUploading
    },
    files() {
      return this.uploadedFiles.map(file => ({
        name: file.file.name,
      }))
    },
  },
  methods: {
    hideModalConfirmation() {
      this.$bvModal.hide('delete-confirmation-modal')
    },
    showConfirmationModal() {
      this.$bvModal.show('delete-confirmation-modal')
    },
    deleteSelectedItem() {
      const id = this.deletingItemId
      const index = this.deletingItemIndex
      if (id) {
        this.isLoading = true
        this.$store
          .dispatch(`${this.MODULE_NAME}/deleteAttachment`, { id })
          .catch(() => {
            this.isLoading = false
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: i18n.t('Error Deleting This file', {
                  module: this.MODULE_NAME,
                }),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            this.$store.dispatch(`${this.MODULE_NAME}/get`, this.contact.id).then(res => {
              const { data } = res.data

              const attachments = data.attachments ?? {}

              this.contact.attachments = [...attachments, ...this.attachmentsArrForSend]
            }).catch(err => {
              console.log(err)
              this.errorNotification(this, err)
            }).finally(() => {
              this.isLoading = false
            })
          })
      } else {
        this.attachments.splice(index, 1)
      }
    },
    formatDate,
    getListOfCategories(value) {
      const list = this.attachmentCategoryListContact
      let theLabelOfFile = ''
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= list.length; i++) {
        if (list[i]?.id === value) {
          theLabelOfFile = list[i]?.name
        }
      }
      return theLabelOfFile
    },
    getDocumentTypeByString(document) {
      let docType = 'Document'
      if (document.includes('image')) {
        docType = 'Image'
      }
      return docType
    },
    truncateFileName(fileName) {
      // Extract file extension
      const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1)

      // Truncate file name, leaving space for extension and ellipsis
      const maxLength = 10 // Adjust as needed
      const truncatedName = fileName.length > maxLength ? `${fileName.substring(0, maxLength - 3)}.` : fileName

      return `${truncatedName}.${fileExtension}`
    },
    showByRef() {
      this.$refs.refLFileUploadInput.clickFileInput()
    },
    showModalToChoseCategory() {
      this.$bvModal.show('attachment-create-modal')
    },
    hideModalToChoseCategory() {
      this.$bvModal.hide('attachment-create-modal')
    },
    showAttachment(file) {
      if (file.id) {
        window.open(file.url, '_blank')
      } else {
        window.open(
          (window.URL || window.webkitURL).createObjectURL(file.file),
          '_blank',
        )
      }
    },
    dropped(e) {
      this.$emit('input', this.onlyAcceptableFiles(e.dataTransfer.files))
    },
    onlyAcceptableFiles(filesArray) {
      // todo add check for it
      return filesArray
    },
    fileInputChanged(e) {
      // this.$emit('input', e.target.files)
      this.chosenFiles = e.target.files
      this.showModalToChoseCategory()
    },
    clearData() {
      this.sendData.attachment_category_id = null
      this.sendData.file = null
      this.chosenFiles = null
      this.$refs.refLFileUploadInput.value = null
    },
    mapDataObject() {
      return {
        ...this.sendData,
        attachment_category_id: this.sendData.attachment_category_id.id,
        file: this.chosenFiles[0],
      }
    },
    postData() {
      this.isUploading = false
      this.hideModalToChoseCategory()
    },
    checkTheFileType(file) {
      const fileType = file.split('/')[1]
      let isErrorAvailable = false
      if (!this.allowedFormats.includes(fileType)) {
        isErrorAvailable = true
        this.$bvToast.toast(this.$t('File format is not accepted'), {
          variant: 'danger',
          solid: true,
          title: this.$t('Error'),
        })
        this.postData()
      }
      return isErrorAvailable
    },
    uploadFile() {
      const file = this.chosenFiles[0]
      const newUploadedDocID = Number(this.sendData.attachment_category_id.id)
      const mappedData = this.attachments.map(item => Number(item.attachment_category_id))
      const exists = mappedData.includes(newUploadedDocID)
      if (this.checkTheFileType(file.type)) {
        return
      }
      if (exists) {
        this.postData()
        this.$bvToast.toast(this.$t('There is an attachment already with the same category'), {
          variant: 'danger',
          solid: true,
          title: this.$t('Error'),
        })
        return
      }
      if (file.size > 4 * 1024 * 1024) {
        // 4MB limit
        this.postData()
        this.$bvToast.toast(this.$t('File size exceeds the limit of 4MB. Please choose a smaller file'), {
          variant: 'danger',
          solid: true,
          title: this.$t('Error'),
        })
        return
      }
      this.isUploading = true
      this.attachments.push(this.mapDataObject())
      this.attachmentsArrForSend.push(this.mapDataObject())
      this.postData()
    },
    deleteAttachment(id, index) {
      this.deletingItemId = id
      this.deletingItemIndex = index
      this.showConfirmationModal()
    },
    uploadAttachment(file) {
      console.log(file)
      if (file) {
        this.show = true
        const fileUrl = file.url
        axios({
          url: fileUrl,
          method: 'GET',
          responseType: 'blob',
        }).then(res => {
          const fileBlob = window.URL.createObjectURL(new Blob([res.data]))
          const fileLink = document.createElement('a')
          fileLink.href = fileBlob
          const fileName = fileUrl.split('/').pop()
          fileLink.setAttribute('download', fileName)
          fileLink.setAttribute('id', 'test-download')
          // fileLink.setAttribute('target', '_blank')
          document.body.appendChild(fileLink)
          fileLink.click()
        }).catch(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: i18n.t('Error Uploading This file', {
                module: this.MODULE_NAME,
              }),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          this.show = false
        })
      }
    },

  },
  setup() {
    const MODULE_NAME = 'contacts'
    const { ACCESS_ABILITY_FOR_CONTACTS } = config()
    return {
      MODULE_NAME,
      ACCESS_ABILITY_FOR_CONTACTS,
    }
  },
}
</script>
