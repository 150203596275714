import { can } from '@core/libs/acl/utils'
import {
  SELECT_INPUT, TEXT_INPUT, SELECT_CHILD_INPUT, L_ACTIVE_CHECK_LF_BOX, CHECKBOX_LEF,
} from '@/views/components/DynamicForm/constants'

export const CONTACT_TYPE_BILLING = 'billing'
export const CONTACT_TYPE_SHIPPING = 'shipping'
export const CONTACT_TYPE_ACCOUNT_HOLDER = 'account_holder'

export default function config() {
  const ACCESS_ABILITY_FOR_ADDRESS = { action: 'change', subject: 'Addresses' }
  // Table Handlers
  const tableColumns = [
    { key: 'actions', label: '', thStyle: { width: can(ACCESS_ABILITY_FOR_ADDRESS.action, ACCESS_ABILITY_FOR_ADDRESS.subject) ? '10%' : '0%' } },
    { key: 'street', label: 'Address' },
    { key: 'city', label: 'City' },
    { key: 'state', label: 'State' },
    { key: 'type', label: 'Type' },
    // { key: 'is_default', label: 'Default' },
  ]
  const fields = {
    street: {
      type: TEXT_INPUT,
      label: 'Street',
      rules: 'required',
      placeholder: 'Type...',
    },
    city: {
      type: TEXT_INPUT,
      label: 'City',
      rules: 'required',
      placeholder: 'Type...',
    },
    state_id: {
      type: SELECT_CHILD_INPUT,
      label: 'State',
      rules: 'required',
      store: 'stateList',
    },
    zip: {
      type: TEXT_INPUT,
      label: 'Zip Code',
      rules: 'required|numeric',
      placeholder: 'Type...',
    },
    reference: {
      type: TEXT_INPUT,
      label: 'Reference',
      placeholder: 'Type...',
    },
    line2: {
      type: TEXT_INPUT,
      label: 'Line 2 (Unit, suite, etc...)',
      placeholder: 'Type...',
    },
    country_id: {
      type: SELECT_INPUT,
      label: 'Country',
      rules: 'required',
      store: 'countryList',
    },
    billing: {
      type: CHECKBOX_LEF,
      label: 'Billing',
    },
    shipping: {
      type: CHECKBOX_LEF,
      label: 'Shipping',
    },
    account_holder: {
      type: CHECKBOX_LEF,
      label: 'Account Holder',
    },
    can_place_orders: {
      type: CHECKBOX_LEF,
      label: 'Can Place Orders',
    },
    is_active: {
      label: 'Active',
      type: L_ACTIVE_CHECK_LF_BOX,
      rules: 'required',
    },
  }
  return {
    tableColumns,
    fields,
    ACCESS_ABILITY_FOR_ADDRESS,
  }
}
