<template>
  <div>
    <error-alert
      :fields="createFields"
      :error="error"
    />
    <b-form>
      <validation-observer ref="contactForm">
        <b-row>
          <b-col md="6">
            <component
              :is="createFields[field].type"
              v-for="field in ['first_name', 'last_name', 'email', 'job_title']"
              :key="field"
              v-model="contact[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
            <div>
              <label class="contacts-label">{{ $t('Preferred contact method') }}</label>
              <b-form-group
                v-slot="{ ariaDescribedby }"
              >
                <b-form-radio
                  v-for="radio in preferredContactMethod"
                  :key="radio.value"
                  v-model="contact['preferred_contact_method']"
                  class="custom-radio-group-input"
                  :aria-describedby="ariaDescribedby"
                  :name="radio.value"
                  :value="radio.value"
                  :disabled="isEdit"
                >
                  {{ $t(radio.title) }}
                </b-form-radio>
              </b-form-group>
            </div>
            <div>
              <component
                :is="createFields[field].type"
                v-for="field in ['birthday']"
                :key="field"
                v-model="contact[field]"
                v-bind="getProps(field)"
                class="mb-0"
                :options="{date: true, datePattern: contact['datePattern'], delimiter: '/'}"
                :input-placeholder="contact['birthdayInputPlaceholder']"
                :is-editable="isEdit"
                @input="checkAge(contact['birthday'])"
              />
              <small
                v-if="invalidDateBirth"
                class="text-danger"
              >Date of birth entered must be over 18 years old</small>
              <component
                :is="createFields[field].type"
                v-for="field in ['is_active']"
                :key="field"
                v-model="contact[field]"
                class="mt-1"
                v-bind="getProps(field)"
                :is-editable="isEdit"
              />
            </div>
            <contact-type :is-edit="isEdit" />
          </b-col>
          <b-col md="6">
            <component
              :is="createFields[field].type"
              v-for="field in ['middle_name']"
              :key="field"
              v-model="contact[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
            <div
              v-for="field in ['phone_one']"
              :key="field"
              class="d-flex"
            >
              <div>
                <component
                  :is="createFields[field].type"
                  v-model="contact[field]"
                  v-bind="getProps(field)"
                  :is-editable="isEdit"
                  :default-country-code="getDefaultCountryCode(`${field}_object`)"
                  @input="handlePhoneInput(field)"
                  @input-obj="setObjectOfPhoneMusk($event, contact,`${field}_object`)"
                />
              </div>
              <div style="width: 20%;">
                <!--                <label class="pt-0">{{ $t('Ext number') }}</label>-->
                <b-form-input
                  v-model="contact[`${field}_ext_val`]"
                  style="height: 38px; margin-top: 28px;"
                  placeholder="ext number"
                  :disabled="isEdit"
                />
              </div>
              <div style="width: 25%; margin-top: 28px;: ">
                <v-select
                  id="countryList"
                  v-model="contact[`${field}_type`]"
                  class="v-select--custom"
                  label="label"
                  :options="phoneOptions"
                  :disabled="isEdit"
                  :placeholder="getPlaceholder(field)"
                />
              </div>
            </div>
            <div
              style="transform: translateY(-10px)"
            >
              <div
                class="text-left inputLabelNormal"
                style="transform: translateY(18px)"
              >
                {{ $t('Additional Phone Numbers') }}
              </div>
              <div
                v-for="field in ['phone_two','phone_three','phone_four']"
                :key="field"
                class="d-flex"
              >
                <div>
                  <component
                    :is="createFields[field].type"
                    v-model="contact[field]"
                    v-bind="getProps(field)"
                    :is-editable="isEdit"
                    style="margin-top: 27px"
                    :default-country-code="getDefaultCountryCode(`${field}_object`)"
                    @input="handlePhoneInput(field)"
                    @input-obj="setObjectOfPhoneMusk($event, contact,`${field}_object`)"
                  />
                </div>
                <div style="width: 20%;">
                  <!--                <label class="pt-0">{{ $t('Ext number') }}</label>-->
                  <b-form-input
                    v-model="contact[`${field}_ext_val`]"
                    style="height: 38px; margin-top: 28px;"
                    placeholder="ext number"
                    :disabled="isEdit"
                  />
                </div>
                <div style="width: 27%;margin-top: 28px ">
                  <v-select
                    id="countryList"
                    v-model="contact[`${field}_type`]"
                    class="v-select--custom"
                    label="label"
                    :options="phoneOptions"
                    :placeholder="getPlaceholder(field)"
                    :disabled="isEdit"
                  />
                </div>
              </div>
            </div>

            <contact-attachment :is-edit="isEdit" />
          </b-col>
        </b-row>
        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <div>
                <b-button
                  class="cancelBtn font-medium-1 font-weight-bolder"
                  variant="outline-primary"
                  @click="switchFunctionOfCancelButton"
                >
                  {{ switchTitleOfCancelButton }}
                </b-button>
              </div>
            </div>
            <div class="d-flex">
              <div class="d-flex mr-2 options-btns">
                <b-button
                  class="options-btn"
                  disabled
                >
                  {{ $t('Invite to Create Web Account') }}
                </b-button>
                <b-button
                  disabled
                  class="options-btn"
                >
                  {{ $t('Send Reset password Link') }}
                </b-button>
              </div>
              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder "
                :disabled="isCreateForm ? false : (!isFormChanged || isLoading)"
                @click="submit"
              >
                <span v-if="!isLoading">
                  {{ $t('SAVE') }}
                </span>
                <b-spinner
                  v-if="isLoading"
                  small
                  label="Small Spinner"
                />
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import {
  BButton,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BModal,
  BOverlay,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { scrollToError } from '@core/utils/utils'
import jsonToFormData from '@/libs/jsonToFormData'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import customerConfig from '@/views/main/customers/customConfig'
import config from '@/views/main/customers/view/contacts/contactsConfig'
import { contactTypesCustomer } from '@/views/main/customers/contactType'
import LSelect from '@/views/components/DynamicForm/components/LSelect.vue'
import CustomerName from '@/views/main/customers/components/CustomerName.vue'
import lFileUpload from '@/views/main/customers/components/LFileUploadAttachments.vue'
import ContactType from '@/views/main/customers/view/contacts/components/ContactType.vue'
import { isString } from '@vueuse/core'
import ContactAttachment from './ContactAttachment.vue'

export default {
  name: 'ContactForm',
  components: {
    ContactType,
    BRow,
    BCol,
    BForm,
    BModal,
    BButton,
    LSelect,
    BOverlay,
    BSpinner,
    BFormGroup,
    BFormInput,
    BFormRadio,
    ErrorAlert,
    lFileUpload,
    CustomerName,
    BFormCheckbox,
    BFormCheckboxGroup,
    ValidationObserver,
    ContactAttachment,
    vSelect,
  },
  props: {
    isCreateForm: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    phoneOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      error: {},
      isLoading: false,
      isSubmit: false,
      invalidDateBirth: false,
      phoneFields: ['phone_one', 'phone_two', 'phone_three', 'phone_four'],
    }
  },
  computed: {
    contact() {
      return this.$store.state[this.MODULE_NAME].contactsForm
    },
    contactFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].customerContactsForm
    },
    isFormChanged() {
      return JSON.stringify(this.contact) !== this.contactFormClone
    },
    switchTitleOfCancelButton() {
      return !this.isFormChanged ? this.$t('Back to List') : this.$t('Cancel')
    },
  },
  mounted() {
    this.unSelectEmpty()
    if (!this.isCreateForm) {
      if (this.$route.query.isEdit) {
        // eslint-disable-next-line no-unused-expressions
        this.$route.query.isEdit === 'true' ? this.$parent.isEditAble() : ''
      }
      const data = {
        ...this.contact,
        ...{
          phone_one_ext_val: JSON.parse(this.$store.state[this.MODULE_NAME].contact.phone_one_object)?.phone_ext_val,
          phone_one_type: JSON.parse(this.$store.state[this.MODULE_NAME].contact.phone_one_object)?.phone_type_val,

          phone_two_ext_val: JSON.parse(this.$store.state[this.MODULE_NAME].contact.phone_two_object)?.phone_ext_val,
          phone_two_type: JSON.parse(this.$store.state[this.MODULE_NAME].contact.phone_two_object)?.phone_type_val,

          phone_three_ext_val: JSON.parse(this.$store.state[this.MODULE_NAME].contact.phone_three_object)?.phone_ext_val,
          phone_three_type: JSON.parse(this.$store.state[this.MODULE_NAME].contact.phone_three_object)?.phone_type_val,

          phone_four_ext_val: JSON.parse(this.$store.state[this.MODULE_NAME].contact.phone_four_object)?.phone_ext_val,
          phone_four_type: JSON.parse(this.$store.state[this.MODULE_NAME].contact.phone_four_object)?.phone_type_val,

          // cell_no_ext_val: JSON.parse(this.$store.state[this.MODULE_NAME].contact.cell_no_object).cell_no_ext_val,
          // phone_no_ext_val: JSON.parse(this.$store.state[this.MODULE_NAME].contact.phone_no_object).phone_no_ext_val,
        },
      }
      this.$store.commit(`${this.MODULE_NAME}/SET_CONTACT_FORM`, data)
      this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_CUSTOMER_CONTACT_FORM_CLONE`, data)
    }
    // setTimeout(() => {
    //   this.$refs.contactForm.reset()
    // }, 0)
  },
  methods: {
    unSelectEmpty() {
      console.log('CONTACT: ', this.contact)
      this.phoneFields.forEach(field => {
        this.contact[`${field}_type`] = null
      })
    },
    handlePhoneInput(field) {
      console.log('123field', field)
      const fieldValue = this.contact[field]
      if (!fieldValue) {
        this.contact[`${field}_type`] = null
      } else {
        this.contact[`${field}_type`] = this.phoneOptions.find(option => option.indexName === field) // Set to the default option
      }
    },
    getPlaceholder(field) {
      console.log('FIELD: ', field)
      return this.phoneOptions.find(option => option.indexName === field).label
    },
    parseDateFormat(formatComing) {
      let format = formatComing
      if (formatComing.search('yyyy') === -1) {
        format = formatComing.replace('yy', 'yyyy')
      }
      // Define regular expressions to match the date format components
      const dayRegex = /dd/g
      const monthRegex = /MM/g
      const yearRegex = /(yyyy|yy)/g

      // Initialize variables to store the positions of each component
      let dayIndex = -1
      let monthIndex = -1
      let yearIndex = -1

      // Find the positions of day, month, and year components in the format string
      const dayMatch = dayRegex.exec(format)
      if (dayMatch) {
        dayIndex = dayMatch.index
      }

      const monthMatch = monthRegex.exec(format)
      if (monthMatch) {
        monthIndex = monthMatch.index
      }

      const yearMatch = yearRegex.exec(format)
      if (yearMatch) {
        yearIndex = yearMatch.index
      }

      // Return an object containing the positions and lengths of each component
      return {
        day: dayIndex,
        month: monthIndex,
        year: yearIndex,
      }
    },
    formatDate(dateString, format) {
      const dateFormat = this.parseDateFormat(format)
      const day = dateString.slice(dateFormat.day, dateFormat.day + 2)
      const month = dateString.slice(dateFormat.month, dateFormat.month + 2)
      const year = dateString.slice(dateFormat.year, dateFormat.year + 4)

      return `${year}-${month}-${day}`
    },
    checkAge(birthDate) {
      const today = new Date()
      const birthdate = new Date(birthDate)
      const age18Date = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate())

      this.invalidDateBirth = birthdate > age18Date
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.isSubmit)
    },

    submit() {
      this.error = {}
      // this.isSubmit = true
      this.$refs.contactForm.validate()
        .then(success => {
          if (success) {
            // this.isLoading = true
            const data = { ...this.contact }

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < this.phoneNumberExtraFields.length; i++) {
              if (data[this.phoneNumberExtraFields[i].extraField]) {
                data[this.phoneNumberExtraFields[i].field][this.phoneNumberExtraFields[i].extraField] = data[this.phoneNumberExtraFields[i].extraField]
              }
            }
            const filteredAttachments = data.attachments.filter(attachment => !attachment.id)
            if (data.phone_one_ext_val && data.phone_one) {
              data.phone_one_object.phone_ext_val = data.phone_one_ext_val
            }

            if (data.phone_two_ext_val && data.phone_two) {
              data.phone_two_object.phone_ext_val = data.phone_two_ext_val
            }

            if (data.phone_three_ext_val && data.phone_three) {
              data.phone_three_object.phone_ext_val = data.phone_three_ext_val
            }

            if (data.phone_four_ext_val && data.phone_four) {
              data.phone_four_object.phone_ext_val = data.phone_four_ext_val
            }

            if (data.phone_one_type && data.phone_one) {
              data.phone_one_object.phone_type_val = data.phone_one_type.label
            }

            if (data.phone_two_type && data.phone_two) {
              data.phone_two_object.phone_type_val = data.phone_two_type.label
            }

            if (data.phone_three_type && data.phone_three) {
              data.phone_three_object.phone_type_val = data.phone_three_type.label
            }

            if (data.phone_four_type && data.phone_four) {
              data.phone_four_object.phone_type_val = data.phone_four_type.label
            }
            const contacts = this.mappingFields(
              Object.keys(this.createFields),
              data,
              {
                id: data.id,
                is_active: +data.is_active,
                attachments: filteredAttachments,
                customer_id: this.$route.params.id,
                phone_one_object: data.phone_one_object ? JSON.stringify(data.phone_one_object) : '',
                phone_two_object: data.phone_two_object ? JSON.stringify(data.phone_two_object) : '',
                phone_three_object: data.phone_three_object ? JSON.stringify(data.phone_three_object) : '',
                phone_four_object: data.phone_four_object ? JSON.stringify(data.phone_four_object) : '',
                preferred_contact_method: data.preferred_contact_method,
                is_billing: data.is_billing ? 1 : 0,
                is_shipping: data.is_shipping ? 1 : 0,
                can_place_orders: data.can_place_orders ? 1 : 0,
                is_account_holder: data.is_account_holder ? 1 : 0,
              },
            )
            // if (contacts.birthday) {
            //   contacts.birthday = this.formatDate(this.contact.birthday, this.contact.birthdayInputPlaceholder)
            //   // contacts.birthday = [date.getFullYear(), date.getMonth() + 1, date.getDate()].map(x => x.toString().padStart(2, '0')).join('-')
            // } else {
            //   contacts.birthday = ''
            // }
            // Converting object of ${ contact } to FormData
            const convertedContactsDataToFormData = jsonToFormData(contacts)
            this.sendNotification(
              this,
              convertedContactsDataToFormData,
              `${this.MODULE_NAME}/${data.id ? 'updateContacts' : 'create'}`,
            )
              .then(() => {
                // this.clear()
              })
              .catch(err => {
                this.error = err.response?.data
              })
              .finally(() => {
                this.isLoading = false
              })
          } else {
            scrollToError(this, this.$refs.contactForm)
          }
        })
    },
    clear() {
      this.$router.push({ name: 'home-customers-contacts' })
    },
    getProps(fieldName) {
      if (this.phoneNumberFields.includes(fieldName)) {
        const JSONPhoneField = this.createFields[fieldName].JSONName
        this.createFields[fieldName].options = {
          'default-country-code': this.contact[JSONPhoneField]?.countryCode,
        }
      }

      return {
        is: this.createFields[fieldName].type,
        field: this.createFields[fieldName],
        name: fieldName,
      }
    },
    switchFunctionOfCancelButton() {
      if (!this.isFormChanged) {
        this.clear()
      } else {
        // this.$refs.contactForm.reset()
        this.cancelFormChanges(this, `${this.MODULE_NAME}/SET_CONTACT_FORM`, this.contactFormClone)
      }
    },
    getDefaultCountryCode(field) {
      if (!this.isCreateForm && this.contact[field] !== null) {
        return isString(this.contact[field]) ? JSON.parse(this.contact[field]).countryCode ?? 'US' : this.contact[field].countryCode ?? 'US'
      }
      return 'US'
    },
  },
  setup() {
    const MODULE_NAME = 'contacts'
    const MODULE_NAME_CLONE = 'cloneData'
    const MODULE_SETTINGS_DEFAULT_CUSTOMER_NAME = 'settings-customer'

    const { setObjectOfPhoneMusk, preferredContactMethod } = customerConfig()
    const {
      createFields, contactTypes, phoneNumberFieldsJSONName, phoneNumberExtraFields, phoneNumberFields,
    } = config()

    return {
      MODULE_NAME,
      contactTypes,
      createFields,
      phoneNumberFields,
      MODULE_NAME_CLONE,
      contactTypesCustomer,
      setObjectOfPhoneMusk,
      preferredContactMethod,
      phoneNumberExtraFields,
      phoneNumberFieldsJSONName,
      MODULE_SETTINGS_DEFAULT_CUSTOMER_NAME,
    }
  },
}
</script>
<style scoped>
.custom-radio-group-input {
  padding-left: 10px !important;
  margin-bottom: 8px !important;
}

.custom-radio-group-input >>> .custom-control-label {
  position: relative !important;
  width: 100% !important;
}

.custom-radio-group-input >>> .custom-control-label::before {
  left: 95.2% !important;
}
</style>
