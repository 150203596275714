<template>
  <div>
    <div>
      <b-form-group>
        <label class="font-weight-bolder">{{ $t('Contact Type') }}</label>
        <b-form-checkbox
          v-for="option in contactTypesCustomer"
          :id="String(option.id)"
          :key="option.id"
          v-model="contact[option.value]"
          class=" contacts-checkbox"
          :disabled="isEdit"
          @change="onChangeHandler(option.value)"
        >
          <span class="pl-1">
            {{ option.name }}
          </span>
          <b-spinner
            v-if="checkingType === option.id && isCheckingType"
            small
            type="grow"
            class="contacts-checkbox-spinner"
          />
        </b-form-checkbox>
      </b-form-group>
    </div>
    <b-modal
      id="show-contact-types-modal"
      ref="show-contact-types-modal"
      centered
      hide-header
      body-class="show-contact-types-modal__body"
      footer-class="show-contact-types-modal__footer justify-content-between"
      :ok-title="modalOnOKTitle"
      ok-variant="success"
      cancel-variant="outline-primary"
      size="lg"
      @hidden="onHidden"
      @hide="onHide"
      @ok="onOk"
    >
      <div>
        <b-row>
          <b-col cols="12">
            <h3
              v-if="occupiedContactType"
              class="l-modal__title"
            >
              {{ $t(`Proceeding will uncheck the ${contactTypeLabel(contactType)} contact on:`) }}
            </h3>
          </b-col>
        </b-row>
        <b-list-group>
          <b-list-group-item
            v-for="oct in occupiedContactType"
            :key="oct.id"
            style="margin-bottom: 10px"
          >
            <b-row>
              <b-col cols="4">
                <span class="font-weight-bolder">
                  {{ oct.first_name }}
                  {{ oct.last_name }}
                </span>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
      </div>
      <template #modal-footer="{ ok, cancel}">
        <b-button
          class="cancelBtn font-medium-1 font-weight-bolder"
          variant="outline-primary"
          @click="cancel()"
        >
          <feather-icon
            icon="LCancelIcon"
            size="16"
          />
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          class="cancelBtn font-medium-1 font-weight-bolder"
          variant="success"
          @click="ok()"
        >
          {{ $t('Proceed') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BModal,
  BSpinner,
  BFormGroup,
  BFormCheckbox,
  BListGroup,
  BListGroupItem, BButton,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'
import config, { CONTACT_TYPE_BILLING, CONTACT_TYPE_ACCOUNT_HOLDER } from '@/views/main/customers/view/contacts/contactsConfig'
import i18n from '@/libs/i18n'
import { CONTACT_TYPE_SHIPPING } from '@/views/main/customers/view/address/addressConfig'

export default {
  name: 'ContactType',
  components: {
    BButton,
    BRow,
    BCol,
    BModal,
    BSpinner,
    BFormGroup,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      checkingType: null,
      isCheckingType: false,
      occupiedContactType: null,
      contactType: null,
      modalOnOKTitle: 'Keep current changes',
    }
  },
  computed: {
    contact() {
      return this.$store.state[this.MODULE_NAME].contactsForm
    },
    customer() {
      return this.$store.state[this.CUSTOMER_MODULE_NAME].customer
    },
  },
  methods: {
    contactTypeLabel(data) {
      let label = ''
      switch (data) {
        case CONTACT_TYPE_ACCOUNT_HOLDER: label = 'account holder'
          break
        case CONTACT_TYPE_SHIPPING: label = 'shipping'
          break
        case CONTACT_TYPE_BILLING: label = 'billing'
          break
        default: label = ''
      }
      return label
    },
    showModal(modalName) {
      this.$bvModal.show(modalName)
    },
    hideModal(modalName) {
      this.$bvModal.hide(modalName)
    },
    onChangeHandler(type) {
      // eslint-disable-next-line no-nested-ternary
      this.contactType = type === 'is_account_holder' ? CONTACT_TYPE_ACCOUNT_HOLDER : null
      if (this.contactType === undefined || this.contactType === null) return

      this.checkingType = this.contactType
      const customerID = this.customer.id
      const contactID = this.contact.id ?? null
      if (!this.contact[type]) return
      this.isCheckingType = true
      if (this.contactType === CONTACT_TYPE_ACCOUNT_HOLDER) {
        this.modalOnOKTitle = 'Proceed'
      } else {
        this.modalOnOKTitle = 'Proceed'
      }

      this.$store.dispatch(`${this.MODULE_NAME}/checkFreeContact`, {
        customer_id: customerID,
        contact_id: contactID,
      }).then(res => {
        const { contacts } = res.data

        if (contacts.length) {
          this.occupiedContactType = contacts
          this.showModal('show-contact-types-modal')
        }
      }).catch(() => {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: i18n.t('Error On Getting Contact Types', {
              module: this.MODULE_NAME,
            }),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }).finally(() => {
        this.checkingType = null
        this.isCheckingType = false
      })
    },
    onHidden() {
      // this.onCancelHandler()
    },
    onHide(evt) {
      if (evt.trigger === 'backdrop') {
        console.log('backdrop')
        // this.onCancelHandler()
      }
    },
    // onCancelHandler() {
    //   this.contact.s_account_holder = true
    // },
    onOk(e) {
      this.$refs['show-contact-types-modal'].$once('hide', bvEvt => {
        if (bvEvt.trigger === 'ok') {
          this.$refs['show-contact-types-modal'].$once('hidden', () => {
            if (this.contactType === CONTACT_TYPE_ACCOUNT_HOLDER) {
              this.unCheckAccountHolderFromOtherContact()
            }
            // this.contact.contact_types.push(this.contactType)
          })
        }
      })
    },
    unCheckAccountHolderFromOtherContact() {
      const contactID = this.occupiedContactType[0].id ?? null
      const customerID = this.occupiedContactType[0].customer_id ?? null
      this.isLoading = true
      this.$store.dispatch(`${this.MODULE_NAME}/removeContactTypes`, {
        customer_id: customerID,
        contact_id: contactID,
      }).then(res => {
        console.log(res)
      }).catch(() => {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: i18n.t('Error On UnChecking Contact Types', {
              module: this.MODULE_NAME,
            }),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }).finally(() => {
        this.modalOnOKTitle = 'Proceed'
      })
    },
    // getContactType(types) {
    //   const typesNames = []
    //
    //   // eslint-disable-next-line no-plusplus
    //   for (let i = 0; i < types.length; i++) {
    //     this.contactTypesCustomer.filter(ctc => (ctc.id === types[i].type ? typesNames.push(ctc.name) : null))
    //   }
    //
    //   return typesNames
    // },
  },
  setup() {
    const MODULE_NAME = 'contacts'
    const CUSTOMER_MODULE_NAME = 'customers'

    const { contactTypesCustomer } = config()

    return {
      MODULE_NAME,
      contactTypesCustomer,
      CUSTOMER_MODULE_NAME,
    }
  },
}
</script>
<style lang="scss">
.contacts-checkbox {

  & .custom-control-label {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between;
    padding-right: 15px;
  }

  & .contacts-checkbox-spinner {
    margin-bottom: 4px;
  }
}
</style>
