export const CONTACT_TYPE_BILLING = 0
export const CONTACT_TYPE_SHIPPING = 1
export const CONTACT_TYPE_ACCOUNT_HOLDER = 2
export const CONTACT_TYPE_CAN_PLACE_ORDERS = 3

export const contactTypesCustomer = [
  {
    id: CONTACT_TYPE_BILLING,
    name: 'Billing',
  },
  {
    id: CONTACT_TYPE_SHIPPING,
    name: 'Shipping',
  },
  {
    id: CONTACT_TYPE_ACCOUNT_HOLDER,
    name: 'Account Holder',
  },
  {
    id: CONTACT_TYPE_CAN_PLACE_ORDERS,
    name: 'Can Place Orders',
  },
]

// export const getCatalogType = type => contactTypes.find(catalog => catalog.id === type)
